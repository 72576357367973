<template>
    <div>
        <div class="app-card-tittle">Flexwerker: {{itemData.person_name}}</div>
        <div class="app-card-tittle">Locatie: {{itemData.location_name}}</div>
        
        <b-row class="p-3">
            <b-col cols="3" class="pr-4 app-local-right-line">
              <span class="col-list-label"><b>{{ trans('declarations',209) }}</b></span>

                <b-row>
                    <b-col cols="6">
                        <span class="col-list-label">{{ trans('hours-worked',207) }}</span>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <span class="col-list-label">€ {{(JSON.parse(itemData.hours_json).total_hours_value).replace('.',',')}}</span>
                    </b-col>
                </b-row>
                
                <b-row v-for="item in JSON.parse(itemData.hours_json).declarations">
                    <b-col cols="6" v-if="![3,4,9].includes(item.id_placement_const_component)">
                        <span class="col-list-label">{{item.component_type_name}}</span>
                    </b-col>
                    <b-col cols="6" v-if="[3,4,9].includes(item.id_placement_const_component)">
                        <span class="col-list-label">{{item.name}}</span>
                    </b-col>
                    <b-col cols="6" class="text-right" v-if="![3,4,9].includes(item.id_placement_const_component)">
                        <span class="col-list-label">€ {{parseFloat(item.amount).toFixed(2).replace('.',',')}}</span>
                    </b-col>
                    <b-col cols="6" class="text-right" v-if="[3,4].includes(item.id_placement_const_component)">
                        <span class="col-list-label">€ {{(parseFloat(item.person_value * item.distance).toFixed(2)).replace('.',',')}}</span>
                    </b-col>
                    <b-col cols="6" class="text-right" v-if="[9].includes(item.id_placement_const_component)">
                        <span class="col-list-label">€ {{parseFloat(item.person_value).toFixed(2).replace('.',',')}}</span>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="text-right">
                        <b>Totaal</b> <span class="col-list-label"><b>€ {{(JSON.parse(itemData.hours_json).total_value).replace('.',',')}}</b></span>
                    </b-col>
                </b-row>
            </b-col>

            <b-col cols="2" class="pr-4 pl-4 app-local-right-line">
                <span class="col-list-label"><b>{{ trans('planned-hours',209) }}</b></span>
                <b-row v-for="item in JSON.parse(itemData.plan_hours_json).hours">
                    <b-col cols="6" v-if="item.type != 2">
                        <span class="col-list-label">{{item.time_from}} - {{item.time_to}}</span>
                    </b-col>
                    <b-col cols="6" v-else>
                        <span class="col-list-label">{{ trans('sh-pause',215) }}</span>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <span class="col-list-label">{{parseInt(item.percent)+100}} %</span>
                    </b-col>
                </b-row>
            </b-col>

            <b-col cols="2" class="pr-4 pl-4 app-local-right-line">
                <span class="col-list-label"><b>{{ trans('real-hours',209) }}</b></span>
                <b-row v-for="item in JSON.parse(itemData.hours_json).hours">
                    <b-col cols="6" v-if="item.type != 2">
                        <span class="col-list-label">{{item.time_from}} - {{item.time_to}}</span>
                    </b-col>
                    <b-col cols="6" v-else>
                        <span class="col-list-label">{{ trans('sh-pause',215) }}</span>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <span class="col-list-label">{{parseInt(item.percent)+100}} %</span>
                    </b-col>
                </b-row>
           
            </b-col>

            <b-col cols="2" class="pr-4 pl-4 app-local-right-line">
                <span class="col-list-label"><b>{{ trans('travel-costs',192) }}</b></span>
                <b-row v-for="item in JSON.parse(itemData.hours_json).declarations">
                    <b-col cols="6" v-if="[3,4].includes(item.id_placement_const_component)">
                        <span class="col-list-label">{{item.name}}</span>
                    </b-col>
                    <b-col cols="6" class="text-right" v-if="[3,4].includes(item.id_placement_const_component)">
                        <span class="col-list-label">{{ (item.distance == null ? ' - ' : item.distance + ' KM')}}</span>
                    </b-col>
                </b-row>
           
            </b-col>

            <b-col cols="3" class="pl-4">
                <span class="col-list-label"><b>{{ trans('notes-flexworker',209) }}</b></span>
                <b-row class="mb-2">
                    <b-col>
                        <span class="col-list-label app-local-description">{{itemData.person_description ? itemData.person_description : '-'}}</span>
                    </b-col>
                </b-row>

                <span class="col-list-label"><b>{{trans('notes-client',209)}}</b></span>
                <b-row>
                    <b-col>
                        <span class="col-list-label app-local-description">{{itemData.client_description ? itemData.client_description : '-'}}</span>
                    </b-col>
                </b-row>
            </b-col>

        </b-row>
    </div>
</template>
<script>
export default {

    props: {
        itemData: {type: Object}
    },

    created(){
    },

    computed: {

    },

    data(){
        return{
        }
    },

    methods: {
        
    }
    
}
</script>
<style scoped>
    .app-local-description{
        white-space: normal !important;
    }

    .app-local-total{
        text-align: right;
    }
    
    .app-local-box{
        background-color: #dcf0f9;
        border-radius: 3px;
        min-height: 130px;
    }

    .app-local-box-header{
        background-color: #174066;
        color: white;
        border-radius: 3px;
    }

    .app-local-right-line {
        border-right: 1px solid #e6e6e6 !important;  
    }
</style>