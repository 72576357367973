import Vue from 'vue'
import axios from "@axios";

export default class UserSettings extends Vue {

    loadSettings(id){
            axios
                .post("core/user-settings/load", {
                    id_user: id
                })
                .then((res) => {           
                    localStorage.setItem('userSettings', JSON.stringify(res.data));
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                });
    }

    async saveSettings(settings, type){
        var userData = JSON.parse(localStorage.getItem('user'));
            await axios
                .post("core/user-settings/save", {
                    id_user: userData.id,
                    settings:JSON.stringify(settings),
                    type: type
                })
                .then((res) => {               
                    localStorage.setItem('userSettings', JSON.stringify(res.data));                   
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                });
    }
}


