<template>
    <b-card>
        <b-row>
            <b-col start>
                <div class="app-card-tittle app-local-title-height">
                    {{trans('declaration-service',209)}} <span v-if="!loading_list">({{ items.total }})</span>
                </div>
            </b-col>
            
            <b-col cols="2" class="pr-0">
                <app-select v-model="filters.office" type="getLocations" :refreshIndex.sync="refresh_locations" clearable/>
            </b-col>

            <b-col cols="2" class="pr-0">
                <app-select v-model="filters.list_type" :options="list_type_options" :refreshIndex.sync="refresh_locations" reduceValue/>
            </b-col>

            <b-col cols="auto" end>
                <app-date-week-selector :weekDates.sync="filters.week" :listType.sync="filters.date_type" :changeArrows="true" :clearable="true" onlyWeek/>
            </b-col>

            <b-col cols="auto" end class="pl-0">
                <b-button-group  size="sm">
                    <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.difference_type !== 1, 'app-list-button-selected':  filters.difference_type === 1 }" @click="setDifferenceType(1)">{{trans('no-deviation',209)}}</b-btn>
                    <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.difference_type !== 2, 'app-list-button-selected':  filters.difference_type === 2 }" @click="setDifferenceType(2)">{{trans('deviant',209)}}</b-btn>
                </b-button-group> 
            </b-col>

            <b-col cols="auto" end class="d-flex align-items-center pl-0">
                    <app-refresh :refresh.sync="filters.refresh" :loading.sync="loading_list"/>
            </b-col>

        </b-row>

        <app-view-table title="Declaratie diensten" :loading="loading_list" :key="refresh" :isTopBar="false" :filters.sync="filters" :items="items" :fields="fields" :customColumns="custom_columns" :customColumnsHeaders="custom_column_headers" @onRowClicked="item=>$set(item, '_showDetails', !item._showDetails)" :isSearch="false" :isFilterOptions="false" class="table-selectable">
            
            <template v-slot:menu-items="cell">
                <!--<b-dropdown-item @click="confirm(cell.data.item)">{{trans('approve-declaration',209)}}</b-dropdown-item>-->
                <!--<b-dropdown-item @click="change(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>-->

                <!--<b-dropdown-divider></b-dropdown-divider>-->
                <b-dropdown-item :to="{ name: 'planning-hours-profile', params: { id_hour_data_main: cell.data.item.id_hour_data_main}}" >Naar profile</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'clients-profile-organization', params: { id_client_data_main: cell.data.item.id_client_data_main }}" >{{trans('to-organization',175)}}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'clients-profile-location', params: { id_location_data_main: cell.data.item.id_location_data_main }}">{{trans('to-location',200)}}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'clients-profile-department', params: { id_department_data_main: cell.data.item.id_department_data_main }}">{{trans('to-department',200)}}</b-dropdown-item>
                <b-dropdown-item :to="{ name: 'people-person-profile', params: { id_person_data_main: cell.data.item.id_person_data_main }}">{{trans('to-flexworker',175)}}</b-dropdown-item>

                <b-dropdown-item v-if="[2, 4, 147].includes(user_id)" @click="recalculate(cell.data.item)">Recalculate to default</b-dropdown-item>

            </template>

            <template v-slot:head_selected>
                <b-form-checkbox v-model="selected_all_rows" class="custom-control-primary" @change="selectAll()" style="margin-top:5px" size="md" />
            </template>

            <template v-slot:selected="cell">
                <b-form-checkbox v-model="cell.data.item.selected" :key="refreshCheck" @change="select($event)" class="custom-control-primary"/>
            </template>

            <template v-slot:row-details="row" >
                <hours-list-details-component :itemData.sync="row.data.item" />
            </template>

            <template v-slot:buttons>
                <b-dropdown v-if="checked_items > 0" no-caret toggle-class="app-button-multi mr-2" variant="secondary-grey">
                    <template #button-content>
                         {{trans('action',3)}} ({{checked_items}})                         
                    </template>

                    <b-dropdown-item @click="confirm(null)">{{trans('approve-declaration',209)}}</b-dropdown-item>    
                    <b-dropdown-item v-if="[2, 4, 147].includes(user_id)" @click="recalculate()">Recalculate to default</b-dropdown-item>

                </b-dropdown>

            </template>

        </app-view-table>

        <!--<hours-profile-confirm v-if="mChange.show" :show.sync="mChange.show" :result.sync="mChange.result" :args.sync="mChange.args" :return.sync="items"/> -->
    </b-card>
</template>
<script>

import axios from "@axios";
import UserSettings from '@core/scripts/UserSettings';
import HoursListDetailsComponent from './HoursListDetailsComponent.vue';
//import HoursProfileConfirm from './Profile/HoursProfileConfirm.vue';

export default {

    components: {
        HoursListDetailsComponent,
        //HoursProfileConfirm,
    },   

    mounted(){
        this.onCreateThis();
    },
    
    watch:{},

    data(){
        return{
            custom_columns: ['selected'],
            custom_column_headers: ['selected'],
            loading_list: false,
            total: 0,
            checked_items: 0,
            items: [],
            refresh:0,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {    
                        clients: {type: 'list', changed: 0, values: [], function: "getClients", parameters: null, widthClass:'app-width-min-350'},  
                        statuses: {type: 'list', changed: 0, values: [], function: "getHourStatuses", parameters: null, widthClass:'app-width-min-350'}, 
                        departments: {type: 'list', changed: 0, values: [], function: "getClientDepartments", parameters: null, widthClass:'app-width-min-350'},
                        persons: {type: 'list', changed: 0, values: [], function: 'getFlexworkers', parameters: null, widthClass:'app-width-min-250'},
                        dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'},
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 4,
                changed: 0,
                week: null,
                office: null,
                date_type: 2,
                difference_type: 1,
            },
            fields: [
                { key: "selected", label: "", thStyle: { "min-width": "40px", "max-width": "40px" }, visible: true  },
                { key: "person_name", label: 'Naam', thStyle: { "width": "100%" }, visible: true, filtering: true, filtering_name: "persons"}, 
                { key: "client_name", label: this.trans('organization',169), thStyle: { "min-width": "160px", "max-width": "160px"}, visible:true, filtering: true, filtering_name: "clients"},
                { key: "department_name", label: this.trans('department',182), thStyle: { "min-width": "120px" }, visible: true, filtering: true, filtering_name: "departments"},
                { key: "date_from", label: this.trans('date',182), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "dates"},
                { key: "plan_time_from", label: 'Gepland', thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true},
                { key: "real_time_from", label: 'Werkelijk', thStyle: { "min-width": "90px", "max-width": "90px" }, visible: true, filtering: false},
                { key: "pause", label: 'Pauze', thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true, filtering: false},
                { key: "total_value_string", label: 'Declaratie', thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: false  },     
                { key: "hour_status_name", label: this.trans('status',3), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "statuses"  },
            ],
            userSettings: new UserSettings(),
            user_id: JSON.parse(localStorage.getItem('user')).id,
            select_all: '0',
            refreshCheck: 0,
            selected_all_rows: false,
            refresh_locations: 0,
            list_type_options: [
                { value: 1, name: this.trans('my-locations',179) }, 
                { value: 2, name: this.trans('my-flex',179) },
                { value: 3, name: this.trans('my-clients',3) }, 
                { value: 4, name: this.trans('d-all-flexworkers',203) }, 
                
            ],
            /*mChange: { 
                show: false, args: {
                                    id_shift_data_main: null,
                                    filters: null,
                                    id_hour_data_main: null,
                                    time_from: null,
                                    time_to: null,
                                    person_description: null,
                                    client_description: null,
                                    hours_json: null,
                                    pause: null,
                                    return_type: null
                                }, 
                result: 0, return: null },*/
        }   
    },

    methods: {

        getList: function(){

            this.loading_list = true;

             axios
                 .post("planning/hours/getList", {
                     filters: JSON.stringify(this.filters)
                 })
                .then((res) => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                    this.total = res.data.items.total
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                    this.checked_items = 0;
                    this.selected_all_rows = false;
                })

        },

        selectAll(){
            
            if(this.selected_all_rows == true){
                this.items.list.forEach(element => {
                    element.selected = true;
                });

                

            } else {
                this.items.list.forEach(element => {
                    element.selected = false;
                });
                this.checked_items = 0;
            }

            this.select();

            this.refreshCheck++;
        },

        selectAll(){
            if(this.selected_all_rows){
                this.items.list.forEach(element => {
                    element.selected = true;
                });

                this.checked_items = this.items.list.length;

            } else {
                this.items.list.forEach(element => {
                    element.selected = false;
                });
                this.checked_items = 0;
            }
            
            this.refreshCheck++;
        },

        select(){
            let i = 0;
            this.items.list.forEach(element => {
                if(element.selected == true){
                    i++;
                }
            });

            this.checked_items = i;

            if(i == this.items.list.length){
                this.selected_all_rows = true
            }else{
                this.selected_all_rows = false 
            }
        },

        recalculate(item){
            this.loading_list = true;

            let ids_shift_data_main = []
            if(item){
                ids_shift_data_main[0] = item.id_shift_data_main
            }else{
                this.items.list.forEach(element => {
                    if(element.selected == true){
                        ids_shift_data_main.push(element.id_shift_data_main)
                    }
                });
            }

            axios
                 .post("planning/hours/recalculatePlacementAndHour", {
                     filters: JSON.stringify(this.filters),
                     ids_shift_data_main: JSON.stringify(ids_shift_data_main)
                 })
                .then((res) => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                    this.total = res.data.items.total
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                })
        },

        change(data){

            /*this.mChange.args.filters = this.filters;
            this.mChange.args.id_hour_data_main = data.id_hour_data_main;
            this.mChange.args.time_from = data.real_time_from;
            this.mChange.args.time_to = data.real_time_to;
            this.mChange.args.person_description = data.person_description ? data.person_description : '';
            this.mChange.args.client_description = data.client_description ? data.client_description : '';
            this.mChange.args.hours_json = JSON.parse(data.hours_json);
            this.mChange.args.pause = data.pause_string;
            this.mChange.args.return_type = "hours_list";
            this.mChange.show = true;*/

        },

        confirm(item){
            
            /*let ids_hour_data_main = []
            if(item){
                ids_hour_data_main[0] = item.id_hour_data_main
            }else{
                this.items.list.forEach(element => {
                    if(element.selected == true){
                        ids_hour_data_main.push(element.id_hour_data_main)
                    }
                });
            }

            this.loading_list = true;

            axios
                 .post("planning/hours/confirmHours", {
                     filters: JSON.stringify(this.filters),
                     ids_hour_data_main: JSON.stringify(ids_hour_data_main)
                 })
                .then((res) => {
                    this.items = res.data.items;
                    this.filters.page = res.data.page;
                    this.total = res.data.items.total
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                })*/

        },


        setDifferenceType(type){
            this.filters.difference_type = type;
        },

        onCreateThis(){

            if([2, 4, 147].includes(this.user_id)){//KKY, MMK, DDA
                this.fields.push({ key: "id_shift_data_main", label: 'Dienst', thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true},);
            }

            this.getList();

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.refresh', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.week', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.office', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.difference_type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.clients.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.departments.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.persons.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.dates.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                   this.getList();
                 }   
            });

        }

    }

    
}
</script>
<style scoped>

</style>